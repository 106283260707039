/*
Theme Name: magical-garden
Theme URI: http://underscores.me/
Author: Underscores.me
Author URI: http://underscores.me/
Description: Description
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: magical-garden
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

magical-garden is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.io/normalize.css/
*/


body.page-template-tp-home {background-image: url("../media/pattern-background.jpg");background-position: center center;background-repeat: no-repeat;background-size: cover;}
body.page-template-tp-home .site-header {box-shadow: none;}
body {color: #272727;font-size: 14px;font-family: adelle-sans, sans-serif;
font-style: normal;
font-weight: 100;}
img {height: auto;max-width: 100%;}

a:hover, a:focus{ text-decoration: none; outline: none;}
a:hover{ color: #6388bb;}
img{ max-width: 100%; height: auto;}
ol, ul{ padding-left: 0;}

html {min-height: 100%;position: relative;overflow-x: hidden;}
body {height: 100%;}
.site-content {margin-bottom: 210px;}
footer {bottom: 0;left: 0;position: absolute;right: 0;}

/*h1, h2, h3, h4, h5, h6 {color: #12422C;}*/

h1, .h1{ font-size: 45px; line-height: normal;color: #12422C;font-family: adelle,serif;font-weight: 700; text-transform: lowercase;}
h2, .h2{ font-size: 37px; line-height: normal;color: #12422C;font-family: adelle,serif;font-weight: 700; text-transform: lowercase;}
h3, .h3{ font-size: 35px; line-height: normal;color: #12422C;font-family: adelle,serif;font-weight: 700; text-transform: lowercase;}
h4, .h4{ font-size: 33px; line-height: normal;color: #12422C;font-family: adelle,serif;font-weight: 700; text-transform: lowercase;}
h5, .h5{ font-size: 30px; line-height: normal;color: #12422C;font-family: adelle,serif;font-weight: 700; text-transform: lowercase;}
h6, .h6{ font-size: 26px; line-height: normal;color: #12422C;font-family: adelle,serif;font-weight: 700; text-transform: lowercase;}

.alignleft {float: none;margin-bottom: 15px;margin-right: 15px;}
.aligncenter {display: block;float: none;margin: 15px auto;}
.alignright {float: right;margin: 0;}

.inner-page-content ol, .inner-page-content ul {margin: 0 0 20px;padding: 0 0 0 18px;}
.inner-page-content ol li, .inner-page-content ul li {list-style-position: outside;padding-left: 10px;font-size: 19px;}




.header-top-content {background-image: url("../media/top-notification-bar.jpg");background-position: center center;background-repeat: no-repeat;background-size: cover;padding: 14px 0;color: #fff;text-align: center;}
.header-top-content p {font-size: 17px;line-height: 20px;margin: 0;font-family: adelle, serif;}
.header-top-content p a {color: #fff;}
.header-top-content p a:hover {text-decoration: none;}
.header-top-content p span {font-weight: 700;}

/*--------------------------------------------------------------
# HEADER
--------------------------------------------------------------*/
.site-header {box-shadow: 1px 1px 4px #bbbbbb;position: relative;z-index: 9;}
.header-section {background-color: #fff;padding: 12px 0 40px;}
.header-logo {width: 38%;float: left;}
.header-logo a:hover {text-decoration: none;}
.header-logo img {display: inline-block;margin-left: -44px;position: absolute;width: 156px;z-index: 9;}
.header-logo .logo-text {display: inline-block;margin-left: 125px;margin-top: 41px;vertical-align: middle;}
.header-logo .logo-text span {display: block;font-size: 52px;line-height: 38px;color: #12422C;font-family: "Alex Brush",cursive;}
.header-logo .logo-text small {color: #272727;display: block;font-family: adelle,serif;font-size: 14px;line-height: 18px;text-align: center;}
.header-menu {float: right; width: 62%;position: relative;padding-right: 8px;}
.main-navigation ul {margin: 0;padding: 0;}
.main-navigation ul li {position: relative;float: left;list-style: none;padding: 0 16px; text-transform: lowercase; }
.main-navigation ul li:last-child {margin-right: 0;}
.main-navigation ul li a {color: #247842;font-size: 20px;line-height: 23px;font-family: adelle, serif;font-weight: 700;}
.main-navigation ul li a:hover {text-decoration: none;color: #12422C;}
.header-socials ul li a img {width: 30px;}
.header-info {width: 100%;float: left;}
.header-info p {display: inline-block;margin-right: 46px;vertical-align: middle;margin-top: 3px;}
.header-info p span {color: #272727;font-size: 16px;}
.header-info p a {color: #272727;font-size: 16px;font-weight: bold;margin-left: 52px;}
.main-navigation {width: 100%;float: left;margin-top: 74px;}

.menu-item-has-children .sub-menu {display: none;min-width: 200px;padding: 7px 0;}
.menu-item-has-children:hover .sub-menu {background-color: #ffffff;display: block;left: 0;position: absolute;top: 100%;z-index: 9;}
.menu-item-has-children .sub-menu li {width: 100%;}
.menu-item-has-children .sub-menu li a {padding: 6px 0;display: block;}
.main-navigation a { color: #000000; font-size: 20px;font-weight: lighter;}
.main-navigation a:hover, .main-navigation li.current_page_item > a{ color: #12422c;}
.main-navigation li.menu-item-has-children .sub-menu li a { color: #247842;}
.main-navigation li.menu-item-has-children .sub-menu li a:hover { color: #12422c;}
.main-navigation li.menu-item-has-children .sub-menu li.current-menu-item a { color: #12422c;}
.main-navigation .menu > li{position: relative;}
.main-navigation .menu > li:last-child{ padding-right: 0;}

.header-socials ul {display: inline-block;margin: 0;padding: 0;vertical-align: middle;}
.header-socials ul li {float: left;list-style: none;margin: 0 5px;}
.header-socials ul li:last-child {margin-right: 0;}
.header-location {float: left;width: auto;}
.header-socials {float: right;position: absolute;text-align: right;top: 27px;padding-right: 8px;}
.nav-mobile {display: none;}






/*--------------------------------------------------------------
# FOOTER
--------------------------------------------------------------*/
.site-footer {background-color: #12422C;box-shadow: 1px 1px 4px #000000;}
.site-footer h6 {color: #ffffff;font-size: 14px;font-weight: bold;letter-spacing: normal;margin-bottom: 0;margin-top: 0;font-family: adelle-sans,sans-serif;}
.footer-location p {margin-bottom: 5px;}
.site-footer .row {padding: 0 20px;}


.footer-logo span {color: #a7cb3b;display: inline-block;font-family: "Alex Brush",cursive;font-size: 52px;line-height: 34px;margin-top: 18px;}
.footer-logo small {color: #ffffff;display: block;font-family: adelle,serif;font-size: 14px;}
.footer-logo p {color: #ffffff;font-size: 14px;margin-top: 20px;padding: 0 80px;}
.footer-logo p a:hover {text-decoration: none;}
.footer-logo a {color: #fff;}

.footer-location {color: #fff;}
.footer-location {/*display: block;*/height: 100%;margin-left: 21px;padding: 33px 40px 30px 75px;}
.footer-location ul {margin-top: 7px;padding: 0;}
.footer-location ul li {display: block;list-style: outside none none;margin-bottom: 10px;padding-left: 37px;position: relative;}
.footer-location ul li i {color: #ffffff;font-size: 19px;left: 2px;position: absolute;top: 0;}
.footer-location ul li a {color: #fff;}
.footer-location ul li a:hover {text-decoration: none;}
.footer-location ul li:nth-of-type(1) a {color: #A7CB3B;}
.footer-location ul li:nth-of-type(3) a {color: #A7CB3B;}

.footer-timing {color: #ffffff;/*display: block;*/height: 100%;margin-left: 5px;padding: 33px 20px 30px 72px;}
.footer-timing span {display: block;}
.footer-timing p {font-family: adelle,serif;font-style: italic;margin-top: 20px;}
.footer-logo {padding: 30px 0;text-align: center;}






.banner-section {background-position: center center;background-repeat: no-repeat;background-size: cover;display: inline-block;position: relative;width: 100%;}
.banner-section img {height: 387px;opacity: 0;visibility: hidden;width: 100%;}
.banner-section:before {background-color: rgba(18, 66, 44, 0.8);content: "";height: 100%;position: absolute;top: 0;width: 100%;}
.banner-content {left: 50%;position: absolute;text-align: center;top: 50%;width: 100%;max-width: 1025px;-webkit-transform: translateX(-50%) translateY(-50%);transform: translateX(-50%) translateY(-50%);}
.banner-section h1 {color: #ffffff;font-family: adelle,serif;font-size: 37px;font-weight: 700;line-height: 44px;margin: 0;}
.banner-section h1 span {color: #c3e55f;font-family: "Alex Brush",cursive;font-size: 87px;font-weight: normal;line-height: 60px;margin-left: 10px;}

.main-section {background-image: url("../media/pattern-background.jpg");background-position: center center;background-repeat: no-repeat;background-size: cover;}

.inner-page-content .wp-caption img { margin-bottom: 10px; }
.inner-page-content .wp-caption-text { font-size: 20px; line-height: normal; color: #12322c; font-family: adelle,serif; font-weight: 700; text-align: center; }

.inner-page-content hr { clear: both; border-top: 1px solid #cccccc; display: block; }
.product-grid-section {padding: 30px 0;}
.product-grid {margin-top: 15px;}
.product-item {float: left;margin: 0 23px 0 0;position: relative;text-align: center;width: 31.58%;background-color: #ffffff;box-shadow: 1px 1px 6px #bbbbbb;}
/*
.product-equal-img {background-position: center center;background-repeat: no-repeat;background-size: cover;position: relative;display: block;}
.product-equal-img img {height: auto;opacity: 0;visibility: hidden;width: 100%;}
*/
.product-item:nth-of-type(3) {margin-right: 0;}
.product-item a:hover {text-decoration: none;}
.product-img {position: relative;}
.small-logo {bottom: -22px;left: 50%;position: absolute;-webkit-transform: translateX(-50%);transform: translateX(-50%);width: 50px;}
.product-title h3 {font-size: 26px;line-height: 30px;margin-top: 14px;}
.product-title h3 a {color: #247842;font-family: adelle,serif;font-weight: 700;}
.product-detail {padding: 15px;}
.product-detail p {color: #272727;margin-top: 16px;padding: 0 8px;}

.right-sidebar {background-color: #a7cb3b;margin-top: -80px;float: left; }
.sidebar-info {padding: 25px 25px 27px;box-shadow: 0 50px 5px #a4a4a4;}
.sidebar-info h3 {color: #12422c;font-size: 26px;line-height: 30px;margin-bottom: 18px;margin-top: 4px;font-family: adelle,serif;font-weight: 700;}
.sidebar-info span {color: #272727;display: block;line-height: 17px;}
.sidebar-info p {color: #ffffff;margin-bottom: 16px;margin-top: 20px;font-family: adelle, serif;font-style: italic;}
.sidebar-info a {color: #12422c;display: block;font-size: 24px;line-height: 28px;text-align: center;font-weight: bold;margin-bottom: 10px;}
.sidebar-info a:hover {text-decoration: none;}
.inner-page-content .sidebar-info p {font-size: 14px;line-height: normal;}
.inner-page-content .sidebar-sale-info p {font-size: 34px;line-height: 26px;}
.inner-page-content .sidebar-sale-info p:first-of-type {margin-top: 0;}

.sidebar-img {position: relative; z-index: 1; background-size: cover;}
.sidebar_image_overlay { content: "\0020"; display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; opacity: .8; background: #a7cb3b; z-index: -1;}
.sidebar-sale-info {color: #ffffff; min-height: 200px; width: 100%; display: flex; align-items: center; flex-direction: column; text-align: center; padding: 25px 25px 27px; }
.sidebar-sale-info .sidebar_callout.header {font-size: 19px;line-height: 19px;color: #fff;font-family: adelle,serif;font-weight: 700; opacity: 1;}
.sidebar-sale-info .sidebar_callout.content {font-size: 30px;line-height: 30px;color: #fff;font-family: adelle,serif;font-weight: 700; opacity: 1;}
.sidebar-sale-info .sidebar_callout.button {font-size: 19px; color: #fff; background-color: #1ba04a; transition: background-color .15s; padding: 5px; border-radius: 3px; opacity: 1;}
.sidebar-sale-info .sidebar_callout.button:hover { background-color: darken(#1ba04a, 15%); }
.sidebar_link { height: 100%; width: 100%; display: inline-block;}

.content-section {padding: 60px 0 50px;}
.content-single-img {padding-right: 40px;}
.content-img {background-position: center center;background-repeat: no-repeat;background-size: cover;position: relative;}
.content-img:before {background-color: rgba(172, 172, 172, 0.2);content: "";height: 100%;position: absolute;top: 0;width: 100%;}
.content-img img {height: auto;opacity: 0;visibility: hidden;width: 100%;}
.content-img p {color: #ffffff;font-size: 24px;font-weight: bold;left: 50%;line-height: 28px;position: absolute;text-align: center;top: 50%;-webkit-transform: translateX(-50%) translateY(-50%);transform: translateX(-50%) translateY(-50%);width: 100%;}
.content-text {padding-left: 4px;}
.content-text p {color: #272727;font-size: 19px;margin-bottom: 36px;}
.content-section ul, .content-section ol {padding: 0 0 0 18px;margin: 0 0 20px;}
.content-section ul li, .content-section ol li {font-size: 19px;list-style-position: outside;padding-left: 10px;}




.inner-page-content {background-color: #ffffff;padding: 36px 0 40px;}
/*.inner-page-content p {clear: both;}*/

.inner-left-content img {margin-bottom: 30px;margin-top: 0;}
.inner-page-content p:first-of-type {margin-top: 9px;}
.inner-page-content .sidebar-info p:first-of-type {margin-top: 20px;}
.inner-page-content p {font-size: 19px;line-height: 22px;line-height: 28px;}
/*.inner-page-content img {width: 100%;height: 100%;}*/
.inner-page-title {padding: 30px 0;background-image: url("../media/pattern-background-inner.jpg");background-position: center center;background-repeat: no-repeat;background-size: cover;}
.inner-page-title h2 {margin: 0;color: #12422C;font-size: 40px;padding: 0 12px;}
.inner-img-title {position: relative;}
.inner-img-title {background-position: center center;background-repeat: no-repeat;background-size: cover;position: relative;margin: 38px 0 50px;}
.inner-img-title img {height: auto;opacity: 0;visibility: hidden;width: 100%;}
.inner-img-title p {color: #ffffff;font-size: 24px;font-weight: bold;left: 50%;line-height: 28px;position: absolute;text-align: center;top: 50%;-webkit-transform: translateX(-50%) translateY(-50%);transform: translateX(-50%) translateY(-50%);width: 100%;}
.inner-image-content {margin-top: 30px;}
.inner-left-content {padding-right: 113px;}
.sidebar-main {margin-top: -10px;}
.sidebar-main .sidebar-info {box-shadow: 0 0 5px #a4a4a4;}

.first {
    clear: both;
    padding-left: 0;
}

.one-half, .three-sixths, .two-fourths {width: 48%;}

.one-half {font-size: 19px;margin-top: 30px;}
.inner-left-content .one-half img {margin-top: 0;}
.one-third {font-size: 19px;margin-top: 30px;}
.inner-left-content .one-third img {margin-top: 0;}
.one-fourth {font-size: 19px;margin-top: 30px;}
.inner-left-content .one-fourth img {margin-top: 0;}
.one-fifth {font-size: 19px;margin-top: 30px;}
.inner-left-content .one-fifth img {margin-top: 0;}
.one-sixth {font-size: 19px;margin-top: 30px;}
.inner-left-content .one-sixth img {margin-top: 0;}
