




@media screen and (max-width: 1365px) {
    .header-logo { width: 40%; }
    .header-menu { width: 59%; }
    .header-logo img {margin-left: 0;}
    .header-logo .logo-text {margin-left: 171px;}
    .main-navigation ul li {padding: 0 10px;}
/*
    .banner-section h1 {font-size: 28px;}
    .banner-section h1 span {font-size: 60px;}
*/
}

@media (min-width: 1200px) and (max-width: 1240px) {
    .container {width: 1170px;}
/*    .header-logo img {width: 130px;}*/
    .header-logo .logo-text {margin-top: 32px;}
    .header-logo .logo-text span {font-size: 49px;}
    .product-item {width: 31.5%;}
    .sidebar-info {padding: 25px 15px;}
    .main-navigation {margin-top: 65px;}
    .header-socials {top: 19px;}
}

@media screen and (max-width: 1199px) {
    .site-content {margin-bottom: 240px;}

    .main-navigation .menu > li{ padding: 0 6px;}
    .main-navigation {margin-top: 78px;}
    .menu-item-has-children .sub-menu {min-width: 170px;}
    .menu-item-has-children .sub-menu li a {padding: 6px 10px;}
    .main-navigation ul li {padding: 0;}

    .header-section {padding: 12px 0 25px;}
    .header-info p {margin-right: 25px;}
    .header-info p a {margin-left: 49px;}
    .header-top-content p {font-size: 14px;}
    .header-logo img {width: 137px;}
    .header-logo .logo-text {margin-left: 102px;margin-top: 36px;}
    .header-logo .logo-text span {font-size: 48px;}
    .header-socials {top: 23px;}
    .main-navigation ul li a {font-size: 17px;}
    .header-logo {width: 41%;}
    .header-menu { width: 59%; }
    .main-navigation {margin-top: 72px;}
    .header-logo img {margin-left: -44px;}
    .product-item {width: 31%;}
    .product-title h3 {font-size: 20px;margin-top: 10px;}
    .sidebar-info {padding: 25px 10px 24px 10px;}
    .sidebar-info h3 {font-size: 20px;margin-bottom: 13px;}
    .sidebar-info span {font-size: 12px;}
    .sidebar-info p {font-size: 13px;margin-bottom: 21px;}
    .product-detail {padding: 15px 0;}
    .product-detail p {font-size: 13px;}
    .sidebar-sale-info {}
    .sidebar-sale-info span {font-size: 15px;}
    .sidebar-sale-info p {font-size: 28px;}
    .content-text p {font-size: 16px;}
    .content-img p {font-size: 20px;}
    .footer-logo span {font-size: 44px;}
    .footer-logo small {font-size: 13px;}
    .footer-logo p {padding: 0 30px;}
    .footer-location {padding: 33px 30px 30px 40px;margin-left: 0;}
    .footer-timing {padding: 33px 20px 30px 42px;}
    .inner-page-content .sidebar-info p {font-size: 13px;}
    .inner-page-content .sidebar-sale-info p {font-size: 28px;line-height: 26px;}
}

@media (min-width: 992px) and (max-width: 1076px) {
    .header-logo img {margin-left: -5px;}
    .header-logo .logo-text {margin-left: 141px;}
    .header-logo .logo-text span {font-size: 42px;}
    .header-logo .logo-text small {font-size: 12px;}
}

@media screen and (min-width: 992px) {
    .mobile-call-us {display: none;}
    .inner-left-content img {margin-bottom: 30px;margin-top: 0;}
}


@media screen and (max-width: 991px) {
    .site-content {margin-bottom: 0;}
    .site-footer, .site-content, .site-header {position: relative;}

    .open-menu .header-menu-icon:after {content: "\f00d"; }
    .site{ -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s; position: relative; right: 0; }
    .open-menu .site {right: 200px; }
    .header-menu {position: fixed; right: -200px;z-index: 99999;top: 0;width: 200px; background-color: #ffffff; -webkit-transition: all 0.3s ease-in-out 0s; transition: all 0.3s ease-in-out 0s;padding-top: 25px;height: 100%;}
    .nav-mobile {background-color: #ffffff;display: block !important;padding: 10px 13px;position: absolute;right: 16px;top: 100px;width: auto;z-index: 9;}
    .open-menu .nav-mobile {/*position: fixed;right: 200px;*/top: auto;}
    #nav-toggle {color: #247842;font-size: 16px;line-height: 19.2px;text-transform: capitalize;vertical-align: sub;font-family: adelle,serif;}
    .open-menu .header-menu { right: 0;}
    .header-menu .header-links {position: relative;margin-bottom: 45px;}
    #nav-toggle:before {color: #247842;content: "\f0c9";display: inline-block;font-family: FontAwesome;font-size: 30px;font-weight: normal;margin-right: 7px;}
    .open-menu #nav-toggle:before {content: "\f00d";/*vertical-align: sub;*/}
    #nav-toggle:before {font-size: 25px;vertical-align: sub;}
    #nav-toggle:hover {text-decoration: none;}
    #nav-toggle:focus {text-decoration: none;}
    .menu-container-inner {height: 100vh;overflow: auto;-webkit-overflow-scrolling:touch;}

    .main-navigation {margin-top: 0;}
    .main-navigation .menu li {width: 100%;padding: 0;}
    .main-navigation .menu li a {display: block;padding: 5px 9px;}
    .menu-item-has-children .sub-menu {display: block;float: none;padding: 0 0 7px 15px;min-width: auto;}
    .menu-item-has-children:hover .sub-menu {position: relative;background-color: transparent;}

    .header-socials {margin-bottom: 40px;position: relative;text-align: left;top: auto;}
    .main-navigation {margin-top: 0;}
    .main-navigation ul li {width: 100%;margin: 0;}
    .main-navigation ul li a {display: block;padding: 5px 15px;font-size: 15px;}
    .header-info p {padding: 5px 9px;margin-right: 0;display: block;margin-bottom: 4px;margin-top: 0;}
    .header-socials ul {width: 100%;padding-left: 4px;}
    .header-socials ul li a img {width: 31px;}
    .banner-section h1 {font-size: 34px;}
    .banner-section h1 span {display: block;font-size: 68px;line-height: 59px;margin-left: 0;margin-top: 10px;}

    .mobile-call-us {position: absolute;right: 152px;top: 112px;}
    .mobile-call-us a {color: #247842;font-size: 18px;line-height: 21px;font-weight: normal;}
    .mobile-call-us a:hover, .mobile-call-us a:focus {text-decoration: none;}
    .header-logo {width: auto;}
    .header-info p {font-size: 13px;}
    .header-info a {display: none;}
    .header-info.header-socials ul li a {display: block;}

    .product-item {margin: 0 18px 0 0;}
    .product-title h3 {font-size: 16px;}
    .product-detail p {font-size: 12px;}
    .sidebar-info h3 {font-size: 16px;}
    .sidebar-info {padding: 15px 10px 18px;}
    .sidebar-info a {font-size: 18px;margin-bottom: 0;}
    .sidebar-sale-info {}
    .sidebar-sale-info span {font-size: 13px;}
    .sidebar-sale-info p {font-size: 22px;line-height: 20px;}
    .sidebar-info p {margin-bottom: 14px;}

    .footer-logo span {font-size: 34px;}
    .footer-logo small {font-size: 10px;}
    .footer-logo p {padding: 0 4px;font-size: 13px;}
    .footer-location {padding: 33px 30px 30px 22px;}
    .footer-location p {font-size: 13px;}
    .footer-location ul li {font-size: 13px;padding-left: 23px;}
    .footer-location ul li i {font-size: 14px;}
    .footer-timing {margin-left: 0;padding: 33px 0 30px 22px;}
    .footer-timing p {font-size: 13px;}
    .footer-timing span {font-size: 13px;}
    .content-text p {font-size: 14px;margin-bottom: 20px;}
    .inner-left-content {padding-right: 12px;}
    .inner-page-content .sidebar-info p {font-size: 13px;}
    .inner-page-content .sidebar-sale-info p {font-size: 22px;line-height: 20px;}
}

@media screen and (min-width: 768px) {
    .site-footer .row {display: flex;height: 100%;}
    .footer-location-main {position: relative;}
    .footer-location-main:after {background-color: #14492f;content: "";height: 100%;left: 33px;position: absolute;bottom: 0;width: 1px;}
    .footer-timing-main {position: relative;}
    .footer-timing-main:after {background-color: #14492f;content: "";height: 100%;left: 15px;position: absolute;bottom: 0;width: 1px;}
}


@media (min-width: 768px) and (max-width: 1199px) {
    .footer-timing-main:after {left: auto;}
    .footer-location-main:after {left: auto;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .mobile-call-us {top: 134px;}
    .nav-mobile {top: 122px;}
    .open-menu .nav-mobile {top: 122px;}
}


@media screen and (max-width: 767px) {
    .alignleft {display: block;float: none;margin: 0 auto;text-align: center;}
    .aligncenter {display: block;float: none;margin: 0 auto;text-align: center;}
    .alignright {display: block;float: none;margin: 0 auto;text-align: center;}

    .mobile-no-padding {padding: 0;}

    .site-footer, .site-content, .site-header {position: relative;}
    .site-content {margin-bottom: 0;}

    .nav-mobile {/*top: auto;*/top: 11px;padding: 0 2px;}
    .mobile-call-us {right: 121px;top: auto;}
    #nav-toggle:before {font-size: 18px;vertical-align: bottom;}
    .mobile-call-us a {font-weight: normal;font-size: 16px;}
    .mobile-call-us a i {margin-right: 5px;}

    .header-section {position: relative;padding: 12px 0 17px;}
    .header-logo {width: 100%;position: unset;}
    .header-logo .logo-text {margin-left: 83px;margin-top: 39px;}
    .header-top-content p {font-size: 12px;line-height: 17px;padding: 0 11px;}
    .header-logo .logo-text span {font-size: 36px;line-height: 26px;}
    .banner-section img {height: 380px;}
    .banner-content {top: 43%;}
    .banner-section h1 {font-size: 24px;line-height: 32px;width: 100%;}
    .banner-section h1 span {font-size: 42px;line-height: 44px;margin-top: 7px;}
    .product-item {width: 100%;margin-bottom: 19px;}
    .right-sidebar {margin-top: 0;}

    .header-logo img {margin-left: -35px;width: 112px;}
    .header-logo .logo-text small {font-size: 10px;line-height: 15px;}


    .product-grid-section {margin-top: -105px;padding: 30px 25px;}
    .product-item {box-shadow: none;}
    .product-img a img {width: 100%;}
    .product-detail {box-shadow: 1px 1px 6px #bbbbbb;padding: 15px 0 23px;}
    .product-title h3 {font-size: 24px;}
    .small-logo {bottom: -18px;width: 40px;}
    .sidebar-info {padding: 25px;box-shadow: 0 3px 5px #a4a4a4;}
    .sidebar-info h3 {font-size: 22px;margin-top: 0;}
    .sidebar-info p {margin-bottom: 10px;margin-top: 10px;font-size: 12px;}
    .inner-page-content .sidebar-info p:first-of-type {margin-top: 10px;}
    .sidebar-info a {font-size: 21px;}
    .sidebar-sale-info {}
    .sidebar-sale-info span {font-size: 17px;}
    .sidebar-sale-info p {font-size: 30px;}
    .content-single-img {padding-right: 12px;margin-bottom: 40px;}
    .content-section {padding: 20px 5px 30px;}
    .content-img p {font-size: 15px;}
    .content-text {padding-left: 0;}
    .content-text p {font-size: 16px;}
    .footer-location {border-left: medium none;padding: 61px 30px 9px 15px;}
    .site-footer h6 {font-size: 12px;font-weight: normal;}
    .footer-location p {margin-bottom: 3px;font-size: 12px;}
    .footer-location ul li {font-size: 13px;padding-left: 30px;margin-bottom: 6px;}
    .footer-location ul li i {font-size: 16px;}
    .footer-location ul li a {font-weight: 500;}
    .footer-timing {border-left: medium none;padding: 7px 0 0 15px;}
    .footer-timing p {font-size: 12px;margin-top: 17px;}
    .footer-timing span {font-size: 12px;}
    .footer-logo {padding: 18px 0 55px;}
    .footer-logo span {font-size: 44px;}
    .footer-logo small {font-size: 12px;}
    .footer-logo p {margin-top: 16px;padding: 0 44px;}

    .product-equal-img > img {width: 100%;}
    .right-sidebar {width: 100%;}

    .inner-left-content {padding-right: 12px;margin-bottom: 20px;}
    .inner-page-title h2 {text-align: center;font-size: 26px;}
    .inner-page-title {padding: 27px 0;}
    .inner-page-content {padding: 30px 5px 40px;}
    .inner-page-content p {font-size: 16px;line-height: 24px;}
    .one-half {font-size: 16px;width: 100%;margin-top: 10px;padding-left: 0;}
    .one-third {font-size: 16px;width: 100%;margin-top: 10px;padding-left: 0;}
    .one-fourth {font-size: 16px;width: 100%;margin-top: 10px;padding-left: 0;}
    .one-fifth {font-size: 16px;width: 100%;margin-top: 10px;padding-left: 0;}
    .one-sixth {font-size: 16px;width: 100%;margin-top: 10px;padding-left: 0;}
    .sidebar-main {padding: 0 20px;}
    .inner-page-content .sidebar-info p {font-size: 12px;}
    .inner-page-content .sidebar-sale-info p {font-size: 30px;line-height: 20px;}
    .sidebar-main {margin-top: 0;}
}

@media (min-width: 480px) and (max-width: 767px) {
    .mobile-call-us {right: 97px;top: 53px;}
    .mobile-call-us a i {margin-right: 1px;}
    .nav-mobile {top: 53px;}
    .open-menu .nav-mobile {top: 53px;}
}
